<script lang="ts">
    import '../../../public/css/pages/lists.css';
    import { inertia, router } from '@inertiajs/svelte';
    import { Icon, Plus } from 'svelte-hero-icons';
    import { Input } from '$lib/components/ui/input';
    import { Button } from '$lib/components/ui/button';
    import {
        Dialog,
        DialogContent,
        DialogHeader,
        DialogTitle,
        DialogDescription,
        DialogFooter,
    } from '$lib/components/ui/dialog';
    import axios from 'axios';
    import toast from 'svelte-french-toast';
    import Alert from '$lib/components/ui/alert/alert.svelte';

    export let lists;
    export let Cookie: string;
    let listName: string = '';
    let dialogOpen: boolean = false;

    const updateList = async (type: string): Promise<void> => {
        const data = {
            name: listName,
            type: type,
        };
        try {
            const response = await axios.post('/my/lists/save', data);
            if (response && response.status === 200) {
                toast.success('List added');
                router.reload({ only: ['lists'] });
                listName = '';
                dialogOpen = false;
            }
        } catch (error) {
            toast.error('Failed to add list');
        }
    };
</script>

<div class="row">
    <div class="min-vh-100 border-right col-12 pr-md-0">
        <div class="d-flex justify-content-between align-items-center border-bottom px-3 pb-3 pt-4">
            <div>
                <h5 class="text-truncate mb-0 font-bold {Cookie}">Lists</h5>
            </div>
            <Button variant="outline" size="sm" class="px-3" on:click="{() => (dialogOpen = true)}">
                <Icon src="{Plus}" class="h-4 w-4" />
            </Button>
        </div>

        <div class="lists-wrapper mt-2" id="lists">
            {#each lists as list, index}
                <div class="px-2">
                    <a
                        href="{list.type !== 'follower' ? `/my/lists/${list.id}` : `/my/lists/follower`}"
                        use:inertia
                        class="list-link d-flex flex-column rounded pb-2 pl-3 pt-2"
                    >
                        <div class="d-flex flex-row-no-rtl justify-content-between">
                            <div>
                                <h5 class="mb-1 font-bold">{list.name}</h5>
                                <span class="text-muted">
                                    {list.members.length}
                                    {list.members.length === 1 ? 'Person' : 'People'} - {list.posts_count}
                                    {list.posts_count === 1 ? 'Post' : 'Posts'}
                                </span>
                            </div>
                            <div class="d-flex align-items-center list-box-avatars-wrapper justify-end pr-3">
                                {#each list.members.slice(0, 3) as member}
                                    <img src="{member.avatar}" class="rounded-circle user-avatar w-2/5" alt="" />
                                {/each}
                            </div>
                        </div>
                    </a>
                </div>
                {#if index !== lists.length - 1}
                    <hr class="my-2" />
                {/if}
            {/each}
        </div>
    </div>
</div>

<Dialog bind:open="{dialogOpen}">
    <DialogContent class="sm:max-w-md">
        <DialogHeader>
            <DialogTitle>Create a new list</DialogTitle>
            <DialogDescription>Enter a name for your new list below.</DialogDescription>
        </DialogHeader>
        <div class="py-4">
            <Input id="list-name" type="text" placeholder="List name" bind:value="{listName}" class="max-w-sm" />
        </div>
        <DialogFooter class="sm:justify-end">
            <Button variant="outline" type="button" on:click="{() => (dialogOpen = false)}" class="mr-2">Cancel</Button>
            <Button on:click="{() => updateList('create')}">Save</Button>
        </DialogFooter>
    </DialogContent>
</Dialog>
